import Autocomplete, { AutocompleteChangeReason, AutocompleteValue } from '@mui/material/Autocomplete';
import { AutocompleteProps, AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete.js';
import TextField from '@mui/material/TextField';
import { TextFieldProps } from '@mui/material/TextField/TextField.js';
import { AutocompleteInputChangeReason } from '@mui/material/useAutocomplete/useAutocomplete.js';
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useStateThrottle } from '../hooks/useStateThrottle.js';
import i18n from '../libraries/i18n.js';
import { useSimpleSearch } from '../libraries/reach/useSimpleSearch.js';
import ApiError from './ApiError.js';

const getACLabelName = <Value extends { name?: string } | string>(v: Value): string =>
  typeof v === 'object' ? v.name || '' : v;
const getACKey = <Value extends { _id?: string } | string>(v: Value): string =>
  typeof v === 'object' ? v._id || '' : v;
export type ACValue<Value, Multiple = false> = AutocompleteValue<Value, Multiple, false, false>;

export interface AutocompleteSearchProps<Value, Multiple extends boolean>
  extends Omit<
    AutocompleteProps<Value, Multiple, false, false>,
    | 'renderInput'
    | 'options'
    | 'filterOptions'
    | 'autoComplete'
    | 'includeInputInList'
    | 'filterSelectedOptions'
    | 'onInputChange'
    | 'onChange'
  > {
  path: string;
  textFieldProps?: TextFieldProps;
  query?: object;
  searchKey?: string;
  disableFilter?: boolean;
  disableEmptySearch?: boolean;
  searchAndSetInit?: boolean;
  onChange: (v: AutocompleteValue<Value, Multiple, false, false>) => void;
}

export function AutocompleteSearch<
  Value extends { _id?: string; name?: string },
  Multiple extends boolean = false,
>({
  path,
  textFieldProps,
  query,
  searchKey = '$name',
  defaultValue,
  disableFilter,
  disableEmptySearch,
  searchAndSetInit,
  onChange,
  ...autoCompleteProps
}: AutocompleteSearchProps<Value, Multiple>) {
  const init = useRef<boolean>(false);
  const [value, setValue] = useState<ACValue<Value, Multiple> | undefined>(
    autoCompleteProps.value || defaultValue
  );
  const disableInit = !searchAndSetInit || !!defaultValue;
  const $nin__id = useMemo(() => {
    if (!value) return [];
    return (Array.isArray(value) ? value.map(getACKey) : [getACKey(value)]).join(',');
  }, [value]);
  const [, data, error, , info, { search }] = useSimpleSearch<Value>(
    path,
    useMemo(
      () => ({
        defaultItems: [],
        limit: 5,
        disableInit,
        query: disableFilter ? query : { ...query, $nin__id },
      }),
      [query, disableFilter, disableInit, $nin__id]
    )
  );

  useEffect(() => {
    if (!init.current && !disableInit && !value && data && data[0]) {
      init.current = true;
      setValue(data[0] as AutocompleteValue<Value, Multiple, false, false>);
      onChange(data[0] as AutocompleteValue<Value, Multiple, false, false>);
    }
  }, [disableInit, value, data, onChange]);

  const handleOnNameSet = useCallback(
    (name: string) => {
      if (!disableEmptySearch || name) {
        search({ [searchKey]: name, $nin__id });
      }
    },
    [search, searchKey, $nin__id, disableEmptySearch]
  );
  const [, setName] = useStateThrottle<string>('', 400, handleOnNameSet);

  const handleOnInputChange = useCallback(
    (_: SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
      if (reason === 'input' || reason === 'clear') {
        setName(() => value);
      }
    },
    [setName]
  );

  const handleOnChange = useCallback(
    (e: SyntheticEvent<any, any>, value: ACValue<Value, Multiple>, reason: AutocompleteChangeReason) => {
      if (e?.nativeEvent?.key === 'Backspace') {
        return;
      }
      if (reason === 'selectOption' || reason === 'clear' || reason === 'removeOption') {
        setValue(value);
        onChange(value);
      }
    },
    [onChange]
  );

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField {...params} label={i18n.t('actions.search')} fullWidth {...textFieldProps} />
    ),
    [textFieldProps]
  );

  return (
    <>
      <Autocomplete<Value, Multiple, false, false>
        renderInput={renderInput}
        options={data}
        value={value}
        filterOptions={(x) => x}
        autoComplete
        getOptionKey={getACKey}
        getOptionLabel={getACLabelName}
        onInputChange={handleOnInputChange}
        noOptionsText={info.hasFetched ? i18n.t('paragraphs.noChoices') : i18n.t('actions.searchToAdd')}
        onChange={handleOnChange}
        disableCloseOnSelect={autoCompleteProps.multiple}
        {...autoCompleteProps}
      />
      {error && <ApiError error={error} />}
    </>
  );
}
