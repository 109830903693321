import { FormEvent, useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AptlyUser } from '@aptly-as/types';
import SendIcon from '@mui/icons-material/Send';
import Tooltip from '@mui/material/Tooltip';
import ActionButtons from '../../components/actions/buttons/ActionButtons';
import { CopyIconButton } from '../../components/actions/icons/Icons';
import { RECRUIT_CAMPAIGN_REF, RECRUIT_CAMPAIGN_URL } from '../../env';
import { useMobile } from '../../hooks/useMobile.js';
import simpleRequest from '../../libraries/fetch/simpleRequest';
import i18n from '../../libraries/i18n';
import { OrganizationContext } from '../../models/Organization/OrganizationContext';
import { ModalActions, ModalContent, ModalTitle } from '../../mui/Dialog';
import Grid from '../../mui/Grid';
import { Checkbox, TextField } from '../../mui/Input';
import Paper from '../../mui/Paper';
import Tabs, { Tab } from '../../mui/Tabs';
import Typography from '../../mui/Typography';
import { UserContext } from '../../models/User/UserContext';
import { copyToClipboard } from '../../utils/window';
import { ModalButton } from '../Modal/ModalButton';

export default function Recruit() {
  const [open, setOpen] = useState(false);
  const [showTip, setShowTip] = useState(false);
  const org = useContext(OrganizationContext);
  const isMobile = useMobile();

  useEffect(() => {
    if (!org.data._id) return;

    if (showTip) {
      setTimeout(() => {
        setShowTip(false);
      }, 4000);
    }

    const opened = localStorage.getItem('recruit-opened');
    if (opened) {
      const now = new Date();
      const then = new Date(opened);
      const diff = now.getTime() - then.getTime();
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      if (days > 30) {
        localStorage.setItem('recruit-opened', new Date().toString());
        setOpen(true);
      }
    } else {
      localStorage.setItem('recruit-opened', new Date().toString());
      setOpen(true);
    }
  }, [org.data._id, showTip]);

  const handleOnClose = useCallback(() => {
    setShowTip(true);
  }, []);

  if (!RECRUIT_CAMPAIGN_URL || !org.data._id) return null;
  if (isMobile) return null;

  return (
    // eslint-disable-next-line react/jsx-no-undef
    <Tooltip title="Du finner meg her senere om du ønsker å verve en venn" open={showTip} arrow>
      <ModalButton
        label={i18n.t('actions.recruitAFriend')}
        dialogProps={{ maxWidth: 'md' }}
        color="warning"
        onClose={handleOnClose}
        open={open}
      >
        <Content endpoint={RECRUIT_CAMPAIGN_URL} />
      </ModalButton>
    </Tooltip>
  );
}

function Content({ endpoint, onClose }: Pick<FormProps, 'endpoint' | 'onClose'>) {
  const { data } = useContext(UserContext);
  const shareUrl = `${RECRUIT_CAMPAIGN_REF}=${data._id}`;
  const [active, setActive] = useState(0);

  return (
    <>
      <ModalTitle>
        <Typography variant="h1">{i18n.t('actions.recruitAFriend')}</Typography>
      </ModalTitle>
      <ModalContent>
        <Typography>
          Hei Kjære Aptly bruker, i år tar vi regninga på julegavene dine! Vi vet at du er en av våre mest
          verdifulle hjelpere, derfor ønsker vi å gi deg 1000 kroner i et gavekort som du kan bruke til
          julegavene eller en måned gratis abonnement for bedriften.
          <br />
          <br />
          Slik fungerer det:
          <ol style={{ marginTop: '.5rem' }}>
            <li>
              Del lenken eller fyll ut skjemaet nedenfor med e-postadressen til noen du kjenner, som ville
              elske å oppdage gleden av Aptly slik som deg.
            </li>
            <li>
              Når en av dine anbefalinger blir kunde hos oss, vil du motta et gavekort på 1000 kroner som vil
              lyse opp din julefeiring!
            </li>
          </ol>
          Du har kanskje en tømrer eller en annen håndverker du kjenner som ville bli lykkelig av å jobbe i
          det samme systemet som deg.
          <br />
          Om du deler din personlige lenke som vist under på Facebook eller LinkedIn øker du sjansene dine og
          vi kommer i ekstra god julestemning sammen ;)
          <br />
          <br />
          Takk for at du er en del av vårt fellesskap!
          <br />
          <br />
        </Typography>
        <Paper>
          <Tabs value={active} onChange={(_e, i) => setActive(i)} aria-label="basic tabs example">
            <Tab label="Verv med skjema" />
            <Tab label="Verv med personlig lenke" />
          </Tabs>
          <PaperContent>
            {active === 0 ? (
              <Form user={data} endpoint={endpoint} onClose={onClose} />
            ) : (
              <Link user={data} shareUrl={shareUrl} />
            )}
          </PaperContent>
        </Paper>
      </ModalContent>
      <ModalActions>
        <ActionButtons onClose={onClose} submitType="button" cancelLabel={i18n.t('actions.close')} />
      </ModalActions>
    </>
  );
}

const PaperContent = styled.div`
  padding: 1rem;
`;

interface FormProps {
  endpoint: string;
  onClose?: () => void;
  user: AptlyUser;
}

function Form({ user, endpoint, onClose }: FormProps) {
  const [busy, setBusy] = useState(false);
  const [sent, setSent] = useState(false);

  const handleOnSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setBusy(true);
      const data = Object.fromEntries(new FormData(e.currentTarget));
      return simpleRequest({
        usePathAsUrl: true,
        type: 'multipart/form-data',
        endpoint,
        method: 'POST',
        data,
        onRequestDone: () => setSent(true),
      });
    },
    [endpoint, onClose]
  );

  if (sent) {
    return (
      <>
        <ModalTitle>
          <Typography variant="h1">{i18n.t('actions.recruitAFriend')}</Typography>
        </ModalTitle>
        <ModalContent>
          <Typography variant="h2">Takk for at du deler!</Typography>
          <Typography>Vi vil kontakte din venn og gi dem muligheten til å bli kunde hos oss.</Typography>
        </ModalContent>
        <ModalActions>
          <ActionButtons onClose={onClose} submitType="button" cancelLabel={i18n.t('actions.close')} />
        </ModalActions>
      </>
    );
  }

  return (
    <Grid container flexDirection="column" gap={2} component="form" onSubmit={handleOnSubmit}>
      <TextField
        name="653aa8ef6a74224ede5804b7-disabled"
        defaultValue={user._id}
        label="Din verveid"
        fullWidth
        required
        disabled
      />
      <input type="hidden" name="653aa8ef6a74224ede5804b7" defaultValue={user._id} required />
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            name="653a97166a74224ede57ed68"
            defaultValue={user.email}
            label="Din e-post"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="653b5a426a74224ede58bfe2"
            defaultValue={user.fullName}
            label="Ditt navn"
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            type="email"
            name="email"
            label="Din venns e-post"
            fullWidth
            autoComplete="off"
            placeholder="din-venn@epost.no"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="name"
            label="Din venns navn"
            fullWidth
            autoComplete="off"
            placeholder="Din Venn"
            required
          />
        </Grid>
      </Grid>
      <div>
        <Typography variant="caption">
          Vi deler din informasjonen i feltene over til den du verver. Slik de vet hvem som har startet
          verveprosessen. Din venn har 1 måned til å bekrefte at de ønsker å bli vervet, etter den tid sletter
          vi informasjonen i hennold til personvern.
        </Typography>
        <Checkbox
          name="samtykke"
          required
          label={
            <>
              Ja, jeg samtykker til at Aptly AS kan lagre informasjonen over i henhold til{' '}
              <a
                href="https://res.cloudinary.com/rubics/image/upload/w_200,h_150,c_fill/v1570198265/adzcsxu7mzi5bmt2iqfe.pdf"
                target="noopener noreferer"
              >
                personvern
              </a>
            </>
          }
        />
      </div>
      <ActionButtons actionLabel={i18n.t('actions.send')} actionIcon={<SendIcon />} disabled={busy} />
    </Grid>
  );
}

interface LinkProps {
  user: AptlyUser;
  shareUrl: string;
}

function Link({ shareUrl }: LinkProps) {
  const handleOnCopy = useCallback(() => copyToClipboard(shareUrl), [shareUrl]);

  return (
    <>
      Del denne teksten direkte til en venn. Du kan også dele lenken på Facebook eller LinkedIn.
      <br />
      ------
      <br />
      Hei, [din venns navn],
      <br />
      <br />
      Jeg kom til å tenke på deg i dag mens jeg jobbet med Aptly Boligportal, som jeg bruker i mitt arbeid.
      Jeg bruker plattformen til å holde kontakt med mine kunder, der jeg kan vise hva som følger med i deres
      bolig og hva vi tilbyr av endringer. Jeg tror dette kan være noe for deg!
      <br />
      <br />
      De har en vervekampanje for øyeblikket som gir deg og din bedrift en måned gratis lisens hvis dere blir
      kunder hos Aptly i løpet av en måned igjennom lenken under.
      <br />
      <br />
      Du kan også lese mer om Aptly på <a href="https://www.aptly.no.">aptly.no</a>
      <br />
      <br />
      <CopyIconButton onClick={handleOnCopy} />
      <a href={shareUrl} target="noopener noreferer">
        {shareUrl}
      </a>
    </>
  );
}
