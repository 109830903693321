import { AptlyScopes, AptlyUnit, AptlyUser } from '@aptly-as/types';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import type { GridRowParams } from '@mui/x-data-grid/models/params';
import * as React from 'react';
import { useCallback, useContext, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SendButtonWithIcon } from '../../components/actions/buttons/Buttons';
import { CopyIconButton, PreviewIconButton, SendIconButton } from '../../components/actions/icons/Icons';
import Search, {
  ISearchSubActions,
  SearchFieldSelect,
  SearchFieldText,
} from '../../components/Search/Search';
import SearchCreateAction from '../../components/Search/search-actions/SearchCreateAction';
import {
  dataGridActionProps,
  DataGridActionWrapper,
  DataGridDeleteAction,
  DataGridGoToAction,
} from '../../components/Search/search-data-grid/data-grid.actions';
import {
  dataGridSearchNameColumn,
  dataGridSearchReferenceColumn,
} from '../../components/Search/search-data-grid/data-grid.fields';
import { SearchCrudContext } from '../../components/Search/search.utils';
import SearchDataGrid, { ISearchDataGridProps } from '../../components/Search/SearchDataGrid';
import { SimpleModal } from '../../components/simple/SimpleCrud';
import SimpleCrud from '../../components/SimpleCrud';
import { unitStatusOptions } from '../../components/simpleCruds/unit/CrudUnitEdit';
import CrudUnitInvitations from '../../components/simpleCruds/unit/CrudUnitInvitations';
import CrudUnitInvite from '../../components/simpleCruds/unit/CrudUnitInvite';
import { createModal } from '../../containers/Modal/ModalContext';
import { Query } from '../../deprecated/Search/Search';
import { SlugLevel, useApiUrl, useSlugs, useTo } from '../../hooks/useGetApiUrl';
import { useMobile } from '../../hooks/useMobile.js';
import filesSaver from '../../libraries/files-saver';
import i18n from '../../libraries/i18n';
import Scope from '../../libraries/scope/Scope';
import { FragmentScope } from '../../libraries/scope/ScopeComponets';
import Button, { ButtonWithIcon } from '../../mui/Button';
import { ModalActions, ModalContent, ModalTitle } from '../../mui/Dialog';
import { TextField } from '../../mui/Input';
import { openPreview } from '../../utils/window';
import { ProjectContext } from '../Project/ProjectContext';
import {
  ICrudUnit,
  ICrudUnitImportSchema,
  unitImportSchema,
  unitSchema,
  unitSchemaFields,
} from './unit.schema';
import { unitStatusLabel } from './unit.utils';

const columns = (isMobile: boolean): GridColDef<AptlyUnit>[] => [
  { ...dataGridSearchNameColumn(), headerName: i18n.t('singles.unit') },
  {
    type: 'string',
    field: 'invites',
    minWidth: 250,
    headerName: i18n.t('singles.invitations'),
    renderCell: (params) => {
      const count = params.row.queueInvites?.length || 0;
      const invites = params.value.filter((x: AptlyUnit['invites'][0]) => !x.claim.claimed);
      return invites.length > 0 ? (
        invites.map((x: AptlyUnit['invites'][0]) => x.claim.email).join(', ')
      ) : (
        <Trans i18nKey="statuses.queuedInvites" count={count}>
          {{ count }} invites in queue
        </Trans>
      );
    },
  },
  {
    type: 'string',
    field: 'users',
    headerName: i18n.t('singles.users'),
    minWidth: 250,
    renderCell: (params) =>
      params.value.length > 0 ? params.value.map((x: AptlyUser) => x.fullName).join(', ') : 'N/A',
  },
  {
    type: 'string',
    field: 'status',
    minWidth: 150,
    headerName: i18n.t('singles.status'),
    renderCell: (params) => unitStatusLabel(params.value),
  },
  dataGridSearchReferenceColumn<AptlyUnit>(
    'unitTemplate',
    i18n.t('singles.unitTemplate'),
    () => ({
      endpoint: useApiUrl(SlugLevel.Project, 'unit-templates'),
      query: { select: '_id,name', as: 'array', limit: '5', sort: 'name' },
      singular: true,
    }),
    {
      renderCell: (params) => {
        if (params.row.unitTemplateEjected) {
          return `--- ${i18n.t('statuses.ejected')} ---`;
        }
        return params.value?.name || params.value;
      },
    }
  ),
  dataGridActionProps((params) => <UnitActions params={params} />, { minWidth: isMobile ? 130 : 280 }),
];

function Units() {
  const { data: project } = useContext(ProjectContext);
  const to = useTo(SlugLevel.Project, '/enheter');
  const navigate = useNavigate();
  const [organizationSlug, projectID] = useSlugs();
  const path = useApiUrl(SlugLevel.Project, 'units');
  const isMobile = useMobile();
  const _columns = useMemo(() => columns(isMobile), [isMobile]);
  const initialState: ISearchDataGridProps['initialState'] = useMemo(
    () => ({
      columns: {
        columnVisibilityModel: {
          status: !isMobile,
          users: !isMobile,
          invites: !isMobile,
          unitTemplate: !isMobile,
        },
      },
    }),
    [isMobile]
  );

  const projectEndpoint = `organizations/${organizationSlug}/projects/${projectID}`;
  const baseEndpoint = `${projectEndpoint}/units`;

  const menuActions: ISearchSubActions<ICrudUnit> = useCallback(
    (_, { refresh }) => {
      const actions = [];

      if (Scope.crud(AptlyScopes.ProjectUnits, 'C')) {
        actions.push({
          label: i18n.t('actions.export'),
          icon: <CloudDownloadOutlinedIcon />,
          onClick: async () => {
            await filesSaver({
              endpoint: `${baseEndpoint}/csv/export`,
              fileName: `${project.name}-unit-export-${Date.now()}.csv`,
            });
          },
        });
        actions.push({
          label: i18n.t('actions.import'),
          icon: <CloudUploadOutlinedIcon />,
          onClick: () => {
            createModal(
              <SimpleModal<ICrudUnitImportSchema>
                title={i18n.t('actions.import')}
                path={`${baseEndpoint}/import`}
                schema={unitImportSchema()}
                fields={['_description', 'type', 'unitTemplate', 'csv']}
                useFieldsProps={{ reachOptions: { type: 'multipart/form-data', fileKeys: ['csv'] } }}
                data={{}}
                onEdit={refresh}
              />
            );
          },
        });
        actions.push({
          label: i18n.t('actions.exportForIntegrations'),
          icon: <CloudDownloadOutlinedIcon />,
          onClick: async () => {
            await filesSaver({
              endpoint: `${baseEndpoint}/csv/export`,
              fileName: `${project.name}-unit-export-${Date.now()}.csv`,
              data: { type: '3rdParty' },
            });
          },
        });
        actions.push({
          label: i18n.t('actions.exportProtocolTransfer'),
          icon: <CloudDownloadOutlinedIcon />,
          onClick: async () => {
            await filesSaver({
              endpoint: `${baseEndpoint}/csv/export`,
              fileName: `${project.name}-unit-export-${Date.now()}.csv`,
              data: { type: 'protocol-transfer' },
            });
          },
        });
      }

      if (Scope.crud(AptlyScopes.ProjectThirdParty, 'U')) {
        actions.push({
          label: i18n.t('paragraphs.linkForThirdPartyUploads'),
          icon: <ArrowForwardIosIcon />,
          onClick: () => {
            createModal(
              <SimpleCrud<{ url: string }>
                title={i18n.t('paragraphs.linkForThirdPartyUploads')}
                description={
                  <div>
                    {i18n.t('info.thirdPartyUnit')}
                    <br />
                    <br />
                    <strong>{i18n.t('paragraphs.linkExpiresOneMonth')}</strong>
                  </div>
                }
                actionLabel={i18n.t('actions.create')}
                endpoint={`${projectEndpoint}/create-third-party-cart-link`}
                onCreate={(data) => createModal(<ShareLinkModal url={data.url} />)}
                fields={[
                  {
                    helpField: i18n.t('paragraphs.supplierName'),
                    name: 'supplierName',
                    type: 'text',
                    value: null,
                    label: '',
                    required: true,
                    autoFocus: true,
                  },
                ]}
              />
            );
          },
        });
      }

      return actions;
    },
    [baseEndpoint, projectEndpoint, project.name]
  );

  const handleOnRowClick: ISearchDataGridProps['onRowClick'] = useCallback(
    (params: GridRowParams) => {
      navigate(`${to}/${params.id}`);
    },
    [navigate, to]
  );

  return (
    <Search<ICrudUnit>
      path={path}
      patchPath={() => path}
      scope={AptlyScopes.ProjectUnits}
      header={{ title: i18n.t('singles.units') }}
      post={{ title: i18n.t('singles.unit') }}
      bulk={{ title: i18n.t('singles.units'), fields: ['unitTemplate'] }}
      patch={{ title: (data) => String(data.name) }}
      schema={unitSchema()}
      fields={unitSchemaFields}
      reach={{
        paginationMode: 'server',
        limit: 100,
        query: {
          sort: 'name',
          select:
            '_id,name,status,users,unitTemplate,unitTemplateEjected,queueInvites,invites,invitesQueue,organizations,projects',
          $populate: 'unitTemplate:_id,name;users:_id,fullName',
          archived: false,
        },
      }}
      buttonActions={() => [
        <SendButtonWithIcon
          key="invites"
          title={i18n.t('actions.bulkInvites')}
          onClick={() => {
            createModal(<CrudUnitInvitations />, { width: 'md' });
          }}
        />,
        <SearchCreateAction key="create" />,
      ]}
      options={{ disableBulk: isMobile }}
      subActions={menuActions}
      searchFields={[
        <SearchFieldText key="name" field="name" queryKey="$name" label={i18n.t('singles.name')} autoFocus />,
        <SearchFieldSelect
          key="status"
          field="status"
          label={i18n.t('singles.status')}
          options={[{ value: '', label: i18n.t('singles.all') }, ...(unitStatusOptions() as any[])]}
        />,
      ]}
    >
      {() => (
        <SearchDataGrid
          columns={_columns}
          onRowClick={handleOnRowClick}
          pageSize={100}
          initialState={initialState}
        />
      )}
    </Search>
  );
}

function UnitActions({ params }: { params: GridRenderCellParams }) {
  const to = useTo(SlugLevel.Project, '/enheter');
  const isMobile = useMobile();
  const [organizationSlug, projectID] = useSlugs();
  const projectEndpoint = `organizations/${organizationSlug}/projects/${projectID}`;
  const baseEndpoint = `${projectEndpoint}/units`;
  const { items, actions } = useContext(SearchCrudContext);
  const { unshift, splice } = actions;
  const unit = params.row;

  const handleOnUpdate = useCallback(
    ({ _id, invites, queueInvites }: AptlyUnit) => {
      const index = items.findIndex((x) => x._id === _id);
      if (index > -1) {
        splice(index, 1, { ...items[index], invites, queueInvites });
      }
    },
    [items, splice]
  );

  return (
    <DataGridActionWrapper>
      <FragmentScope scope={AptlyScopes.ProjectUnits} crud="U">
        <PreviewIconButton
          onClick={() => {
            openPreview(`${unit._id}/velkommen/`);
          }}
        />
      </FragmentScope>
      {!isMobile && (
        <>
          <FragmentScope scope={AptlyScopes.ProjectUnits} crud="C">
            <CopyIconButton
              onClick={() =>
                createModal(
                  <SimpleModal
                    title={i18n.t('actions.copy')}
                    schema={unitSchema()}
                    fields={unitSchemaFields}
                    path={baseEndpoint}
                    data={unit}
                    onEdit={unshift}
                    useFieldsProps={{
                      subPath: 'copy',
                      forcePatch: ['name', 'unitTemplate'],
                      alwaysPost: true,
                    }}
                  />
                )
              }
            />
            <DataGridDeleteAction params={params} />
          </FragmentScope>
          <FragmentScope scope={AptlyScopes.ProjectUnitInvites} crud="C">
            <SendIconButton
              title={i18n.t('actions.sendInvitation')}
              onClick={() =>
                createModal(<CrudUnitInvite unit={unit} endpoint={baseEndpoint} onCreate={handleOnUpdate} />)
              }
            />
          </FragmentScope>
        </>
      )}
      <DataGridGoToAction params={params} preTo={to} />
    </DataGridActionWrapper>
  );
}

type ExportType = {
  baseEndpoint: string;
  label?: string;
  overrideQuery?: Query;

  // Overridden with SearchViewComposer. Use overrideQuery if not working
  query?: Query;
};

/** @deprecated Use SearchExportButton or ExportButtonV2 **/
function Export(props: ExportType) {
  const { baseEndpoint, label = i18n.t('actions.export'), query = {}, overrideQuery = {} } = props;

  const data: Query = { ...query, ...overrideQuery };
  delete data.limit;

  return (
    <ButtonWithIcon
      variant="outlined"
      color="primary"
      onClick={() =>
        filesSaver({
          endpoint: `${baseEndpoint}/report/export`,
          fileName: 'rapport.csv',
          data,
        })
      }
    >
      {label}.csv
      <CloudDownloadOutlinedIcon />
    </ButtonWithIcon>
  );
}

type ShareLinkModalProps = {
  url: string;
  onClose?: () => void;
};

function ShareLinkModal(props: ShareLinkModalProps) {
  const { url, onClose } = props;

  return (
    <React.Fragment>
      <ModalTitle>{i18n.t('paragraphs.linkForThirdPartyUploads')}</ModalTitle>
      <ModalContent>
        <p>{i18n.t('info.thirdPartyUnitShare')}</p>
        <TextField value={url} fullWidth onFocus={(e) => e.target.select()} />
      </ModalContent>
      <ModalActions>
        <Button color="primary" onClick={onClose}>
          {i18n.t('actions.close')}
        </Button>
      </ModalActions>
    </React.Fragment>
  );
}

export default Units;
export { Export };
