import { AptlyOrganization, AptlyProject, AptlyProjectInjection, AptlyScopes } from '@aptly-as/types';
import AccessTime from '@mui/icons-material/AccessTime';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AttachMoney from '@mui/icons-material/AttachMoney';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CategoryIcon from '@mui/icons-material/CategoryOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import Functions from '@mui/icons-material/Functions';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Label from '@mui/icons-material/Label';
import LocationCity from '@mui/icons-material/LocationCity';
import PaymentIcon from '@mui/icons-material/Payment';
import SendIcon from '@mui/icons-material/Send';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import Star from '@mui/icons-material/Star';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import WebOutlinedIcon from '@mui/icons-material/WebOutlined';
import Report from '../../containers/Admin/Report';
import { SidebarList } from '../../containers/Sidebar/sidebar.utils';
import { SlugLevel } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import Scope from '../../libraries/scope/Scope';
import Typography from '../../mui/Typography';
import Algorithms from '../Algorithm/Algorithms';
import { Booking } from '../Booking/Booking';
import { Documents } from '../Document/Documents';
import Emails from '../Email/Emails';
import Inquiries from '../Inquiry/Inquiries';
import OptionLabels from '../OptionLabel/OptionLabels';
import OrdersV1 from '../Order/OrdersV1';
import Pages from '../Page/Pages';
import Payments from '../Payment/Payments.js';
import Periods from '../Period/Periods';
import ProductsV2 from '../Product/Products';
import Recommendations from '../Recommendation/Recommendations';
import Units from '../Unit/Units';
import UnitTemplates from '../UnitTemplate/UnitTemplates';
import UpsellTemplates from '../UpsellTemplates/UpsellTemplates';
import ProjectDashboard from './ProjectDashboard';
import ProjectEmailsStatus from './ProjectEmailsStatus';
import ProjectExtraOptions from './projectExtraOptions/ProjectExtraOptions';
import { toProjectIntegrationItems } from './ProjectIntegrations';
import ProjectInvites from './ProjectInvites/ProjectInvites';
import ProjectSettings from './ProjectSettings';

const projectRoutes = (
  organizationData: AptlyOrganization,
  projectData: AptlyProject
): SidebarList<AptlyProjectInjection>[] => [
  {
    injection: AptlyProjectInjection.Base,
    scope: AptlyScopes.Project,
    mobile: true,
    items: [
      {
        scope: AptlyScopes.Project,
        path: '',
        icon: <DashboardIcon />,
        label: i18n.t('singles.dashboard'),
        element: <ProjectDashboard />,
        mobile: true,
      },
    ],
  },
  {
    injection: AptlyProjectInjection.Options,
    scope: [AptlyScopes.OrganizationProducts, AptlyScopes.ProjectProducts],
    subheader: i18n.t('paragraphs.setupOfOptions'),
    items: [
      {
        injection: AptlyProjectInjection.Products,
        scope: [AptlyScopes.OrganizationProducts, AptlyScopes.ProjectProducts],
        label: i18n.t('singles.products'),
        icon: <CategoryIcon />,
        items: organizationData.producer
          ? [
              {
                path: 'organisasjonsprodukter',
                scope: AptlyScopes.OrganizationProducts,
                label: i18n.t('singles.producerProducts'),
                icon: <CategoryIcon />,
                element: <ProductsV2 key={SlugLevel.Organization} level={SlugLevel.Organization} />,
              },
            ]
          : [
              {
                path: 'prosjektprodukter',
                scope: AptlyScopes.ProjectProducts,
                label: i18n.t('singles.projectProducts'),
                icon: <CategoryIcon />,
                element: <ProductsV2 key={SlugLevel.Project} level={SlugLevel.Project} />,
              },
              {
                path: 'organisasjonsprodukter',
                scope: AptlyScopes.OrganizationProducts,
                label: i18n.t('singles.organizationProducts'),
                icon: <CategoryIcon />,
                element: <ProductsV2 key={SlugLevel.Organization} level={SlugLevel.Organization} />,
              },
              {
                path: 'produkter',
                scope: AptlyScopes.AdminProducts,
                label: i18n.t('singles.aptlyProducts'),
                icon: <CategoryIcon />,
                element: <ProductsV2 key={SlugLevel.Base} level={SlugLevel.Base} />,
              },
            ],
      },
      {
        injection: AptlyProjectInjection.Content,
        scope: [
          AptlyScopes.ProjectRecommendations,
          AptlyScopes.ProjectPages,
          AptlyScopes.OrganizationOptionLabels,
          AptlyScopes.ProjectAlgorithms,
          AptlyScopes.ProjectEmails,
        ],
        label: i18n.t('singles.content'),
        icon: <InsertDriveFileOutlinedIcon />,
        items: [
          {
            scope: AptlyScopes.ProjectRecommendations,
            path: 'maler/anbefalinger',
            icon: <Star />,
            label: i18n.t('singles.recommendations'),
            element: <Recommendations />,
          },
          {
            scope: AptlyScopes.OrganizationOptionLabels,
            path: 'maler/merkelapper',
            icon: <Label />,
            label: i18n.t('singles.labels'),
            element: <OptionLabels />,
          },
          {
            scope: AptlyScopes.ProjectPages,
            path: 'sider',
            icon: <WebOutlinedIcon />,
            label: i18n.t('singles.pages'),
            element: <Pages />,
          },
          {
            scope: AptlyScopes.ProjectAlgorithms,
            path: 'kalkyler',
            icon: <Functions />,
            label: i18n.t('singles.calculations'),
            element: <Algorithms />,
          },
          {
            scope: AptlyScopes.ProjectEmails,
            path: 'emails',
            icon: <EmailOutlinedIcon />,
            label: i18n.t('singles.emailtemplates'),
            element: <Emails />,
          },
        ],
      },
      {
        scope: AptlyScopes.ProjectUpsellTemplates,
        path: 'maler/oppsalg',
        icon: <AttachMoney />,
        label: i18n.t('singles.upsell'),
        element: <UpsellTemplates />,
      },
      {
        scope: AptlyScopes.ProjectPeriods,
        path: 'perioder',
        label: (
          <Typography variant="body2">
            {i18n.t('singles.deadlines')}
            <br />
            <i>{i18n.t('singles.optionPeriods')}</i>
          </Typography>
        ),
        icon: <AccessTime />,
        element: <Periods />,
      },
      {
        scope: AptlyScopes.ProjectUnitTemplates,
        path: 'tilvalg',
        label: (
          <Typography variant="body2">
            {i18n.t('singles.catalogs')}
            <br />
            <i>{i18n.t('singles.optionLists')}</i>
          </Typography>
        ),
        icon: <StyleOutlinedIcon />,
        element: <UnitTemplates />,
      },
      {
        scope: [AptlyScopes.ProjectUnitTemplates, AptlyScopes.ProjectUnitExtraOptions],
        every: true,
        path: 'prosjekt-tilvalg',
        label: i18n.t('singles.fees'),
        icon: <AddShoppingCartIcon />,
        element: <ProjectExtraOptions />,
      },
    ],
  },
  {
    injection: AptlyProjectInjection.Project,
    scope: AptlyScopes.Project,
    subheader: i18n.t('singles.project'),
    mobile: true,
    items: [
      {
        scope: AptlyScopes.ProjectUnits,
        path: 'enheter',
        icon: <LocationCity />,
        label: (
          <Typography variant="body2">
            {i18n.t('singles.customers')}
            <br />
            <i>{i18n.t('singles.units')}</i>
          </Typography>
        ),
        element: <Units />,
        mobile: true,
      },
      {
        scope: AptlyScopes.ProjectDocuments,
        path: 'dokumenter',
        icon: <FileCopyOutlinedIcon />,
        label: i18n.t('singles.documents'),
        element: <Documents level={SlugLevel.Project} scope={AptlyScopes.ProjectDocuments} />,
        mobile: true,
      },
      ...toProjectIntegrationItems(projectData),
      {
        scope: AptlyScopes.ProjectOrders,
        path: 'ordre',
        icon: <ShoppingCartOutlinedIcon />,
        label: i18n.t('singles.order'),
        element: <OrdersV1 />,
      },
      {
        scope: [AptlyScopes.ProjectOrders, AptlyScopes.ProjectPayment],
        every: true,
        crud: 'U',
        path: 'betaling',
        label: i18n.t('singles.payment'),
        icon: <PaymentIcon />,
        element: <Payments />,
      },
      {
        scope: AptlyScopes.ProjectReport,
        path: 'rapporter',
        icon: <InsertChartOutlinedIcon />,
        label: i18n.t('singles.reports'),
        element: <Report showPrice={Scope.crud(AptlyScopes.ProjectReportPrice, 'R')} />,
      },
      {
        scope: AptlyScopes.AdminTesting,
        path: 'email-status',
        icon: <EmailOutlinedIcon />,
        label: i18n.t('singles.emails'),
        element: <ProjectEmailsStatus />,
      },
    ],
  },
  {
    injection: AptlyProjectInjection.Support,
    scope: [AptlyScopes.ProjectBooking, AptlyScopes.ProjectInquiry],
    subheader: i18n.t('singles.clientCommmunication'),
    mobile: true,
    items: [
      {
        scope: AptlyScopes.ProjectBooking,
        to: 'motebooking',
        path: 'motebooking/*',
        icon: <CalendarMonthOutlinedIcon />,
        label: i18n.t('singles.bookAMeeting'),
        element: <Booking />,
        mobile: true,
      },
      {
        scope: AptlyScopes.ProjectInquiry,
        to: 'kundesupport',
        path: 'kundesupport/*',
        icon: <ChatOutlinedIcon />,
        label: i18n.t('singles.support'),
        element: <Inquiries />,
        mobile: true,
      },
    ],
  },
  {
    injection: AptlyProjectInjection.Admin,
    scope: AptlyScopes.Project,
    crud: 'U',
    subheader: i18n.t('singles.admin'),
    items: [
      {
        scope: AptlyScopes.Admin,
        path: 'invitasjoner',
        label: i18n.t('singles.invites'),
        icon: <SendIcon />,
        element: <ProjectInvites />,
      },
      {
        scope: AptlyScopes.Project,
        crud: 'U',
        path: 'instillinger',
        label: i18n.t('singles.settings'),
        icon: <SettingsOutlinedIcon />,
        element: <ProjectSettings />,
      },
    ],
  },
];

export default projectRoutes;
