import { AptlyAdminInjection, AptlyScopes } from '@aptly-as/types';
import AppsIcon from '@mui/icons-material/Apps';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import CategoryIcon from '@mui/icons-material/CategoryOutlined';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { SlugLevel } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { Apps } from '../../models/App/Apps/Apps';
import AdminModules from '../../models/Module/AdminModules';
import Orders from '../../models/Order/Orders';
import OrderItems from '../../models/Order/OrdersItems';
import Payments from '../../models/Payment/Payments.js';
import PaymentSettlements from '../../models/PaymentSettlement/PaymentSettlements.js';
import Producers from '../../models/Producer/Producers';
import Products from '../../models/Product/Products';
import Suppliers from '../../models/Supplier/Suppliers';
import Tags from '../../models/Tag/Tags';
import { SidebarList } from '../Sidebar/sidebar.utils';
import AdminBilling from './AdminBilling';
import { AdminDashboard } from './AdminDashboard';
import TripletexPlayground from './TripletexPlayground.js';

export const adminRoutes = (): SidebarList<AptlyAdminInjection>[] => [
  {
    injection: AptlyAdminInjection.Base,
    scope: AptlyScopes.Admin,
    items: [
      {
        scope: AptlyScopes.Admin,
        path: '',
        label: i18n.t('singles.dashboard'),
        icon: <DashboardIcon />,
        element: <AdminDashboard />,
      },

      {
        scope: AptlyScopes.Admin,
        path: 'tripletex',
        label: i18n.t('singles.tripletex'),
        icon: null,
        element: <TripletexPlayground />,
      },
    ],
  },
  {
    injection: AptlyAdminInjection.Content,
    subheader: i18n.t('singles.content'),
    scope: [
      AptlyScopes.AdminTags,
      AptlyScopes.AdminProducts,
      AptlyScopes.AdminProducers,
      AptlyScopes.AdminSuppliers,
    ],
    items: [
      {
        scope: AptlyScopes.AdminProducts,
        path: 'produkter',
        label: i18n.t('singles.products'),
        icon: <CategoryIcon />,
        element: <Products level={SlugLevel.Base} />,
      },
      {
        scope: AptlyScopes.AdminProducers,
        path: 'produsenter',
        label: i18n.t('singles.producers'),
        icon: <BuildOutlinedIcon />,
        element: <Producers />,
      },
      {
        scope: AptlyScopes.AdminTags,
        path: 'emneknagger',
        label: i18n.t('singles.tags'),
        icon: <LocalOfferOutlinedIcon />,
        element: <Tags />,
      },
      {
        scope: AptlyScopes.AdminSuppliers,
        path: 'leverandorer',
        label: i18n.t('singles.suppliers'),
        icon: <LocalShippingOutlinedIcon />,
        element: <Suppliers />,
      },
    ],
  },
  {
    injection: AptlyAdminInjection.Modules,
    subheader: i18n.t('singles.modules'),
    scope: AptlyScopes.Admin,
    items: [
      {
        scope: AptlyScopes.AdminModules,
        path: 'modules',
        label: i18n.t('singles.modules'),
        icon: <ViewModuleIcon />,
        element: <AdminModules />,
      },
      {
        scope: AptlyScopes.AdminApps,
        path: 'apps',
        label: i18n.t('singles.apps'),
        icon: <AppsIcon />,
        element: <Apps />,
      },
    ],
  },
  {
    injection: AptlyAdminInjection.Admin,
    subheader: i18n.t('singles.admin'),
    scope: AptlyScopes.Admin,
    items: [
      {
        scope: AptlyScopes.AdminBilling,
        path: 'fakturering',
        label: i18n.t('singles.billing'),
        icon: <InsertChartOutlinedIcon />,
        element: <AdminBilling />,
      },
      {
        scope: AptlyScopes.Admin,
        path: 'ordre',
        label: i18n.t('singles.orders'),
        icon: <InsertChartOutlinedIcon />,
        element: <Orders />,
      },
      {
        scope: AptlyScopes.Admin,
        path: 'ordre-produkter',
        label: i18n.t('singles.products'),
        icon: <InsertChartOutlinedIcon />,
        element: <OrderItems />,
      },
      {
        scope: AptlyScopes.AdminReport,
        path: 'betaling',
        label: i18n.t('singles.payment'),
        icon: <ShoppingCartOutlinedIcon />,
        element: <Payments />,
      },
      {
        scope: AptlyScopes.AdminReport,
        path: 'payment-settlements',
        label: i18n.t('singles.paymentSettlements'),
        icon: <ReceiptOutlinedIcon />,
        element: <PaymentSettlements />,
      },
    ],
  },
];
