import { AptlyFieldType, AptlyProducer, AptlyProducerTrademark, AptlyScopes } from '@aptly-as/types';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl.js';
import { acceptImages } from '../../libraries/react-dropzone/drop-zone.utils';
import Scope from '../../libraries/scope/Scope';
import { IBulkEdit, ICrudField, ICrudSchema } from '../../components/crud/utils/crud.utils';
import {
  schemaEmail,
  schemaBulkIds,
  schemaName,
  tagsSchema,
} from '../../components/crud/schema/fields.schema';
import i18n from '../../libraries/i18n';

export interface ICrudProducer extends AptlyProducer, IBulkEdit {}

export const producerSchema = (): ICrudSchema<ICrudProducer> => ({
  ids: schemaBulkIds(),
  name: schemaName({
    autoFocus: true,
  }),
  email: schemaEmail({
    required: false,
  }),
  url: {
    type: AptlyFieldType.Text,
    label: i18n.t('singles.url'),
    defaultValue: '',
  },
  tags: tagsSchema({}),
  logo: {
    type: AptlyFieldType.Image,
    defaultValue: null,
    label: i18n.t('singles.logo'),
    image: {
      accept: acceptImages,
      mediaKey: 'logoMedia',
      width: 160,
      opts: {
        disableFullWidth: true,
        style: { maxHeight: '50px', maxWidth: '250px', width: 'auto' },
        transforms: 'c_fit',
      },
    },
  },
  featured: {
    type: AptlyFieldType.Switch,
    defaultValue: false,
    label: i18n.t('singles.featured'),
    renderValidate: () => Scope.crud(AptlyScopes.Admin),
  },
});

export function producerSchemaField<T extends object>(
  override: Partial<ICrudField<T, AptlyProducer | string | undefined | null>> = {}
): ICrudField<T, AptlyProducer | string | undefined | null> {
  return {
    type: AptlyFieldType.AutocompleteSearch,
    useAutocompleteSearch: () => ({
      path: useApiUrl(SlugLevel.Base, 'producers'),
      query: { archived: false, select: '_id,name' },
    }),
    label: i18n.t('singles.producer'),
    defaultValue: null,
    ...override,
  };
}

export const producerTrademarkSchema = (): ICrudSchema<AptlyProducerTrademark> => ({
  name: schemaName({
    autoFocus: true,
  }),
  logo: {
    type: AptlyFieldType.Media,
    defaultValue: null,
    label: i18n.t('singles.logo'),
    image: {
      accept: acceptImages,
      width: 160,
      opts: {
        disableFullWidth: true,
        style: { maxHeight: '50px', maxWidth: '250px', width: 'auto' },
        transforms: 'c_fit',
      },
    },
  },
});
