import { AptlyFieldType, AptlyTag } from '@aptly-as/types';
import { schemaName } from '../../components/crud/schema/fields.schema';
import { ICrudField, ICrudSchema } from '../../components/crud/utils/crud.utils';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl.js';
import i18n from '../../libraries/i18n.js';

export const tagSchema = (): ICrudSchema<AptlyTag> => ({
  name: schemaName({
    autoFocus: true,
  }),
});

export function tagsSchemaField<T extends object>(
  override: Partial<ICrudField<T, AptlyTag[]>>
): ICrudField<T, AptlyTag[]> {
  return {
    type: AptlyFieldType.AutocompleteSearch,
    useAutocompleteSearch: () => ({
      path: useApiUrl(SlugLevel.Base, 'tags'),
      multiple: true,
      query: { archived: false, select: '_id,name' },
    }),
    label: i18n.t('singles.tags'),
    defaultValue: [],
    ...override,
  };
}
