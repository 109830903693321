import { AptlyScopes, AptlyUnitTemplate } from '@aptly-as/types';
import { useGet } from '@ewb/reach-react';
import Remove from '@mui/icons-material/Remove';
import Tooltip from '@mui/material/Tooltip';
import { useCallback, useMemo, useState } from 'react';
import { CreateButtonWithIcon, SendButtonWithIcon } from '../../components/actions/buttons/Buttons';
import ApiError from '../../components/ApiError';
import BusyWrapper from '../../components/BusyWrapper';
import ModuleLocked from '../../components/ModuleLocked';
import Section from '../../components/Section';
import CrudCreateUnitProduct from '../../components/simpleCruds/product/CrudCreateUnitProduct';
import { createModal } from '../../containers/Modal/ModalContext';
import ExtraOrder from '../Order/ExtraOrder';
import Spacer from '../../components/Spacer';
import StyledIntroDetailed from '../../components/StyledIntroDetailed';
import useGetApiUrl, { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import Scope from '../../libraries/scope/Scope';
import Divider from '../../mui/Divider';
import Grid from '../../mui/Grid';
import Tabs, { Tab } from '../../mui/Tabs';
import { tightSpacing } from '../../utils/spacing';
import { useProject } from '../Project/ProjectContext';
import { Category, UnitProduct } from '../UnitTemplate/unit-template.types';
import { ExtraOption } from './unit.types';
import { useUnit } from './UnitContext';
import CrudReturnProduct from './unitProductBasket/CrudReturnProduct';
import UnitProductTable from './UnitProductTable';

export default function UnitOptionChanges() {
  if (!Scope.crud(AptlyScopes.ProjectUnitExtraOptions)) {
    return <ModuleLocked />;
  }

  return <Content />;
}

function Content() {
  const unit = useUnit();
  const project = useProject();
  const [busy, setBusy] = useState(false);
  const algorithms = (project && project.algorithms) || [];
  const projectUrl = useGetApiUrl('project');
  const unitUrl = `${projectUrl}/units/${unit._id}`;
  const endpoint = `${unitUrl}/extra-options`;
  const moduleActive = Scope.crud(AptlyScopes.ProjectUnitExtraOptions);
  const unitTemplatesEndpoint = useApiUrl(
    SlugLevel.Project,
    `unit-templates/${(unit.unitTemplate as AptlyUnitTemplate)?._id}/categories`
  );
  const [categoriesState] = useGet<Category[]>(unitTemplatesEndpoint, { disableInit: !unit.unitTemplate });

  const [tabState, setTabState] = useState<0 | 1 | 2 | 3>(0);
  const [state, refresh] = useGet<UnitProduct[]>(
    endpoint,
    useMemo(() => ({ disableInit: !moduleActive }), [moduleActive])
  );

  const allProducts = state.data;

  const [confirmedItems, nextOrderItems, items, revertedItems] = useMemo(() => {
    return (allProducts || []).reduce<UnitProduct[][]>(
      (arr, product) => {
        if (product.confirmDate) {
          arr[product.revertItemRef ? 3 : 0].push(product);
        } else if (product.addToNextOrder) {
          arr[1].push(product);
        } else {
          arr[2].push(product);
        }
        return arr;
      },
      [[], [], [], []]
    );
  }, [allProducts]);

  const handleReturnProduct = useCallback(() => {
    createModal(
      <CrudReturnProduct
        unit={unit}
        filterItems={[
          ...items.filter((x) => x.revertItemRef).map((x) => x.revertItemRef!),
          ...nextOrderItems.filter((x) => x.revertItemRef).map((x) => x.revertItemRef!),
        ]}
        onCreate={refresh}
      />,
      { width: 'md' }
    );
  }, [unit, items, nextOrderItems, refresh]);

  const handleAddOrEditProduct = useCallback(
    (unitProduct?: UnitProduct | ExtraOption) => {
      createModal(
        <CrudCreateUnitProduct
          endpoint={endpoint}
          onSave={refresh}
          unitProduct={unitProduct as UnitProduct}
          showDBProduct
          disablePeriods
          categories={categoriesState.data}
        />
      );
    },
    [endpoint, refresh, categoriesState.data]
  );

  const handleCreateOrder = useCallback(() => {
    createModal(
      <ExtraOrder
        unit={unit}
        products={items}
        endpoint={`${unitUrl}/orders/extra-job`}
        onSave={refresh}
        onJobWait={setBusy}
      />,
      { width: 'md' }
    );
  }, [unit, unitUrl, items, refresh]);

  return (
    <BusyWrapper busy={state.busy}>
      <Section>
        {state.error && <ApiError error={state.error} />}
        <StyledIntroDetailed
          title={i18n.t('singles.extraOptions')}
          headerActions={
            <Grid container spacing={tightSpacing}>
              <Tooltip
                title="Reverser tilvalg er midlertidlig pauset når funksjon ikke fungerer slik den skal"
                placement="bottom"
              >
                <Grid item>
                  <CreateButtonWithIcon
                    title={i18n.t('actions.revertOption')}
                    onClick={handleReturnProduct}
                    endIcon={<Remove />}
                    disabled
                  />
                </Grid>
              </Tooltip>
              <Grid item>
                <CreateButtonWithIcon
                  title={i18n.t('actions.add')}
                  onClick={() => handleAddOrEditProduct()}
                  disabled={busy}
                />
              </Grid>
              <Grid item>
                <SendButtonWithIcon
                  title={i18n.t('actions.sendOrder')}
                  onClick={handleCreateOrder}
                  disabled={busy || items.length === 0}
                />
              </Grid>
            </Grid>
          }
          description={i18n.t('paragraphs.extraOrders')}
        >
          <Tabs
            value={tabState}
            indicatorColor="secondary"
            textColor="secondary"
            onChange={(_e, v) => setTabState(v)}
          >
            <Tab label={i18n.t('statuses.readyForOrder') + ` (${items.length})`} />
            <Tab label={i18n.t('statuses.queuedForOrder') + ` (${nextOrderItems.length})`} />
            <Tab label={i18n.t('statuses.confirmed') + ` (${confirmedItems.length})`} />
            <Tab label={i18n.t('statuses.reverted') + ` (${revertedItems.length})`} />
          </Tabs>
          <Divider />
          <Spacer />

          {tabState === 0 && (
            <UnitProductTable
              key={tabState}
              endpoint={endpoint}
              products={items}
              algorithms={algorithms}
              onDelete={refresh}
              createOrder={handleCreateOrder}
              addProduct={handleAddOrEditProduct}
              onEdit={handleAddOrEditProduct}
              categories={categoriesState.data}
              disabled={busy}
            />
          )}

          {tabState === 1 && (
            <UnitProductTable
              key={tabState}
              endpoint={endpoint}
              products={nextOrderItems}
              algorithms={algorithms}
              onDelete={refresh}
              categories={categoriesState.data}
            />
          )}

          {tabState === 2 && (
            <UnitProductTable
              key={tabState}
              endpoint={endpoint}
              products={confirmedItems}
              algorithms={algorithms}
              categories={categoriesState.data}
            />
          )}

          {tabState === 3 && (
            <UnitProductTable
              key={tabState}
              endpoint={endpoint}
              products={revertedItems}
              algorithms={algorithms}
              categories={categoriesState.data}
            />
          )}
        </StyledIntroDetailed>
      </Section>
    </BusyWrapper>
  );
}
