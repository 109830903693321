import { AutocompleteSelect } from '../../AutocompleteSelect.js';
import { ISimpleComponentProps } from './simple-components.utils';

export type ICrudSimpleAutocompleteSelectProps<T extends object> = ISimpleComponentProps<T, any>;

export default function SimpleAutocompleteSelect<T extends object>({
  field,
  onChange,
}: ICrudSimpleAutocompleteSelectProps<T>) {
  const { value, defaultValue, disabled, label, useAutocompleteSelect } = field;
  if (!useAutocompleteSelect) {
    throw new Error(`Field "${field}" is missing useAutocompleteSelect object`);
  }
  const autocompleteProps = useAutocompleteSelect();
  const _defaultValue = autocompleteProps.options.find((x) => x.value === value || defaultValue);
  const _value = autocompleteProps.options.find((x) => x.value === value);

  return (
    <AutocompleteSelect
      defaultValue={_defaultValue}
      value={_value}
      onChange={onChange}
      disabled={disabled}
      textFieldProps={{ label, disabled }}
      {...autocompleteProps}
    />
  );
}
