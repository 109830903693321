import {
  AptlyDocumentType,
  AptlyFieldType,
  AptlyMediaSrc,
  AptlyOrganization,
  AptlyProduct,
  AptlyProductImage,
  AptlyProductVariant,
  AptlyScopes,
} from '@aptly-as/types';
import { useContext } from 'react';
import AptlyMedia from '../../components/AptlyMedia';
import { CustomProductProducts } from '../../components/crud/custom/CustomProductProducts';
import {
  schemaBulkIds,
  schemaColor,
  schemaDescription,
  schemaFile,
  schemaName,
  schemaProducer,
} from '../../components/crud/schema/fields.schema';
import {
  IBulkEdit,
  ICrudField,
  ICrudFieldSimpleGroup,
  ICrudSchema,
} from '../../components/crud/utils/crud.utils';
import LinkDocument from '../../components/LinkDocument';
import { SlugLevel } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { acceptCsv, acceptImages } from '../../libraries/react-dropzone/drop-zone.utils';
import Scope from '../../libraries/scope/Scope';
import { producerSchemaField } from '../Producer/producer.schema.js';
import { tagsSchemaField } from '../Tag/tag.schema.js';
import { ProductContext } from './ProductContext';

export interface IProductSchema extends AptlyProduct, IBulkEdit {
  __imagePreview?: boolean;
}
export interface AptlyProductVariantSchema extends AptlyProductVariant {
  __imagePreview?: boolean;
}

export const scopeProductExtended = () =>
  Scope.crud(AptlyScopes.Admin) || Scope.crud(AptlyScopes.OrganizationProductsExtended);
export const productNewKeys: (keyof IProductSchema)[] = [
  '__imagePreview',
  'name',
  'productNumber',
  'title',
  'producer',
  'itemNumber',
  'unitCost',
  'netCost',
  'eanNumber',
  'tags',
  'description',
  'color',
  'colorLabel',
  'url',
  'series',
  'style',
  'material',
  'length',
  'width',
  'height',
  'depth',
  'circumference',
  'volume',
  'images',
];

export const productEditKeys: (keyof IProductSchema)[] = [...productNewKeys, 'products', 'documents'];

export const variantNewKeys: (keyof AptlyProductVariantSchema)[] = [
  '__imagePreview',
  'name',
  'productNumber',
  'title',
  'description',
  'color',
  'colorLabel',
  'series',
  'style',
  'material',
  'length',
  'width',
  'height',
  'images',
];
export const variantEditKeys: (keyof AptlyProductVariantSchema)[] = [...variantNewKeys, 'documents'];

export const productBulkKeys: (keyof AptlyProduct)[] = [
  'producer',
  'tags',
  'series',
  'style',
  'material',
  'length',
  'width',
  'height',
  'circumference',
];

const smallText: Pick<ICrudField<any, string>, 'type' | 'defaultValue' | 'gridProps'> = {
  type: AptlyFieldType.Text,
  defaultValue: '',
  gridProps: { gridColumn: 'span 4' },
};

const tinyText: Pick<ICrudField<any, string>, 'type' | 'defaultValue' | 'gridProps'> = {
  type: AptlyFieldType.Text,
  defaultValue: '',
  gridProps: { gridColumn: 'span 2' },
};

const baseSchema = (
  update?: boolean,
  product?: AptlyProduct
): ICrudSchema<IProductSchema | AptlyProductVariant> => {
  const isDisabled = !update;
  const productScope = scopeProductExtended();
  const override = (k: keyof AptlyProduct, p?: AptlyProduct): any => (p && p[k] ? { placeholder: p[k] } : {});
  const extraFieldGroup: ICrudFieldSimpleGroup<any> = {
    name: i18n.t('singles.extraFields'),
  };
  return {
    __imagePreview: {
      type: AptlyFieldType.Custom,
      defaultValue: true,
      disabled: true,
      label: '',
      gridProps: { gridColumn: 'span 4', gridRow: 'span 4', alignSelf: 'center' },
      customRender: (crud) => {
        const { value } = crud.getField('images');
        if (value && value.length) {
          const { image, src } = value[0];
          return (
            <AptlyMedia
              src={image || src}
              media={value[0]}
              width={320}
              crop="fit"
              style={{ height: 'auto', width: '100%', maxHeight: '230px', objectFit: 'contain' }}
              opts={{ transforms: 'c_pad' }}
            />
          );
        }
        return <></>;
      },
    },
    productNumber: {
      type: AptlyFieldType.Text,
      defaultValue: '',
      label: i18n.t('singles.productNumber'),
      gridProps: { gridColumn: 'span 3' },
      disabled: isDisabled,
      ...(product ? override('productNumber', product) : {}),
    },
    name: {
      ...schemaName(override('name', product)),
      disabled: isDisabled,
      gridProps: { gridColumn: 'span 8' },
    },
    title: {
      label: i18n.t('info.productTitle'),
      type: AptlyFieldType.Text,
      defaultValue: '',
      gridProps: { gridColumn: 'span 5' },
      disabled: isDisabled,
    },
    itemNumber: {
      label: i18n.t('singles.itemNumber'),
      type: AptlyFieldType.Text,
      defaultValue: '',
      gridProps: { gridColumn: 'span 4' },
      disabled: isDisabled,
    },
    eanNumber: {
      label: i18n.t('singles.eanNumber'),
      type: AptlyFieldType.Text,
      defaultValue: '',
      gridProps: { gridColumn: 'span 4' },
      disabled: isDisabled,
    },
    description: schemaDescription({ label: '', disabled: isDisabled }),
    color: schemaColor({
      group: extraFieldGroup,
      disabled: isDisabled || !productScope,
      ...override('color', product),
      ...smallText,
    }),
    colorLabel: {
      group: extraFieldGroup,
      label: i18n.t('singles.colorLabel'),
      disabled: isDisabled || !productScope,
      ...smallText,
    },
    series: {
      group: extraFieldGroup,
      label: i18n.t('singles.series'),
      disabled: isDisabled || !productScope,
      ...smallText,
    },
    style: {
      group: extraFieldGroup,
      label: i18n.t('singles.style'),
      disabled: isDisabled || !productScope,
      ...smallText,
    },
    material: {
      group: extraFieldGroup,
      label: i18n.t('singles.material'),
      disabled: isDisabled || !productScope,
      ...smallText,
    },
    length: {
      group: extraFieldGroup,
      label: i18n.t('singles.length'),
      disabled: isDisabled || !productScope,
      ...tinyText,
    },
    width: {
      group: extraFieldGroup,
      label: i18n.t('singles.width'),
      disabled: isDisabled || !productScope,
      ...tinyText,
    },
    height: {
      group: extraFieldGroup,
      label: i18n.t('singles.height'),
      disabled: isDisabled || !productScope,
      ...tinyText,
    },
    depth: {
      group: extraFieldGroup,
      label: i18n.t('singles.depth'),
      disabled: isDisabled || !productScope,
      ...tinyText,
    },
    circumference: {
      group: extraFieldGroup,
      label: i18n.t('singles.circumference'),
      disabled: isDisabled || !productScope,
      ...tinyText,
    },
    volume: {
      group: extraFieldGroup,
      label: i18n.t('singles.volume'),
      disabled: isDisabled || !productScope,
      ...tinyText,
    },
    images: {
      type: AptlyFieldType.Images,
      defaultValue: [],
      label: i18n.t('singles.images'),
      saveAfterSet: true,
      disabled: isDisabled,
      preValue: (_, v: any): AptlyMediaSrc[] =>
        v.map((x: any) => ({
          ...x,
          src: x.src || x.image,
          alt: x.alt || x.description || '',
        })),
      preOnChange: (v: AptlyMediaSrc[]): AptlyProductImage[] =>
        v.map((x) => ({
          ...x,
          image: x.src,
        })),
      group: { name: i18n.t('singles.images') },
      image: {
        width: 540,
        accept: acceptImages,
        srcKey: 'image',
        crop: 'pad',
        ...(!productScope ? { maxLength: 1 } : {}),
      },
    },
  };
};

const useDocumentLevel = (product: AptlyProduct, orgProducer?: AptlyOrganization['producer']) => {
  if (orgProducer) return SlugLevel.Organization;
  return product.project ? SlugLevel.Project : product.organization ? SlugLevel.Organization : SlugLevel.Base;
};
const useDocumentScope = (level: SlugLevel) =>
  Scope.crud(
    level === SlugLevel.Project
      ? AptlyScopes.ProjectProducts
      : level === SlugLevel.Organization
        ? AptlyScopes.OrganizationProducts
        : AptlyScopes.AdminProducts,
    'U'
  );
const useDocumentLevelAndScope = (product: AptlyProduct, orgProducer?: AptlyOrganization['producer']) => {
  const level = useDocumentLevel(product, orgProducer);
  const scope = useDocumentScope(level);
  return { level, scope: { create: scope, read: true, update: scope, delete: scope } };
};
export const productSchema = (
  orgProducer: AptlyOrganization['producer'],
  update?: boolean
): ICrudSchema<IProductSchema> => {
  const productScope = scopeProductExtended();
  const isDisabled = !update;

  return {
    ids: schemaBulkIds(),
    producer: producerSchemaField({
      disabled: isDisabled || !!orgProducer,
      gridProps: { gridColumn: 'span 4' },
    }),
    tags: tagsSchemaField({
      gridProps: { gridColumn: 'span 12' },
      disabled: isDisabled,
    }),
    documents: {
      group: { name: i18n.t('singles.documents') },
      type: AptlyFieldType.Documents,
      defaultValue: [],
      label: i18n.t('singles.documents'),
      disabled: isDisabled,
      useDocument: (crud) => {
        const { level, scope } = useDocumentLevelAndScope(crud.state.data, orgProducer);
        return {
          level,
          scope,
          overridePath: `products/${crud.state.data._id}/documents`,
          ...(!productScope ? { maxLength: 1, types: [AptlyDocumentType.FDV] } : {}),
        };
      },
    },
    variants: {
      type: AptlyFieldType.Sort,
      defaultValue: [],
      label: i18n.t('singles.variants'),
      disabled: isDisabled,
    },
    url: {
      group: {
        name: i18n.t('singles.extraFields'),
      },
      type: AptlyFieldType.Text,
      defaultValue: undefined,
      label: i18n.t('singles.url'),
      gridProps: { gridColumn: 'span 4' },
      disabled: isDisabled || !productScope,
    },
    unitCost: {
      type: AptlyFieldType.Number,
      defaultValue: undefined,
      label: i18n.t('singles.indicativeCost'),
      gridProps: { gridColumn: 'span 4' },
      renderValidate: () => !orgProducer,
      disabled: isDisabled || !productScope || !!orgProducer,
    },
    netCost: {
      type: AptlyFieldType.Number,
      defaultValue: undefined,
      label: i18n.t('singles.producerIndicativeCost'),
      gridProps: { gridColumn: 'span 4' },
      renderValidate: () => !!orgProducer,
    },
    products: {
      type: AptlyFieldType.Custom,
      group: { name: i18n.t('singles.variants') },
      defaultValue: [],
      label: i18n.t('singles.variants'),
      CustomComponent: CustomProductProducts,
      disabled: isDisabled,
    },
    ...(baseSchema(update) as ICrudSchema<IProductSchema>),
  };
};

export const productVariantSchema = (product?: AptlyProduct): ICrudSchema<AptlyProductVariant> => ({
  ...(baseSchema(true, product) as ICrudSchema<AptlyProductVariant>),
  documents: {
    group: { name: i18n.t('singles.documents') },
    type: AptlyFieldType.Documents,
    defaultValue: [],
    label: i18n.t('singles.documents'),
    useDocument: (crud) => {
      const { product } = useContext(ProductContext);
      const { level, scope } = useDocumentLevelAndScope(product);
      return {
        level,
        scope,
        overridePath: `products/${product!._id}/variants/${crud.state.data._id}/documents`,
      };
    },
  },
});

export interface IAptlyProductImport extends Pick<AptlyProduct, '_id' | 'producer'> {
  _description?: string;
  type: 'product' | 'link-variants';
  file: File | null;
  onlyEdit?: boolean;
}

export const productImportSchema = (
  orgProducer: AptlyOrganization['producer']
): ICrudSchema<IAptlyProductImport> => ({
  _description: {
    type: AptlyFieldType.Custom,
    defaultValue: '',
    label: '',
    customRender: () => (
      <LinkDocument
        href="https://docs.google.com/spreadsheets/d/17-z7klwHo2QV4rbY4KwpQVI934k1rVbxx8BI3eERXmQ/template/preview?usp=sharing"
        label="Produkt import.csv"
      />
    ),
  },
  type: {
    type: AptlyFieldType.Select,
    defaultValue: 'product',
    label: i18n.t('singles.type'),
    options: [
      { value: 'product', label: i18n.t('singles.products') },
      { value: 'link-variants', label: i18n.t('singles.variants') },
    ],
  },
  file: schemaFile(acceptCsv, { required: true }),
  producer: schemaProducer({
    gridProps: {},
    renderValidate: (crud) => !orgProducer && crud.getField('type').value === 'product',
  }),
  onlyEdit: {
    type: AptlyFieldType.Switch,
    label: i18n.t('actions.onlyEdit'),
    defaultValue: false,
  },
});
