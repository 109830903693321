import { Container } from '@mui/material';
import { useState } from 'react';
import { AutocompleteSearch } from '../../components/AutocompleteSearch.js';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl.js';
import Typography from '../../mui/Typography.js';

export default function TripletexPlayground() {
  const path = useApiUrl(SlugLevel.Base, 'tripletex/ledger/account');
  const [account, setAccount] = useState<any>(null);

  return (
    <Container>
      <Typography variant="h1" gutterBottom>
        Tripletex funksjoner
      </Typography>
      <AutocompleteSearch<{ id: string; name: string }>
        path={path}
        defaultValue={null}
        onChange={setAccount}
        searchKey="number"
        query={{ archived: false }}
        textFieldProps={{ label: 'Søk med kontonr f.eks 1000' }}
        getOptionKey={(x) => x.id}
      />
      {account && (
        <Typography>
          {account.id}: {account.name}
        </Typography>
      )}
    </Container>
  );
}
