import React, { useContext } from 'react';
import { AptlyAlgorithm, AptlyProduct } from '@aptly-as/types';
import {
  AddIconButton,
  DownloadIconButton,
  EditIconButton,
  SendIconButton,
} from '../../components/actions/icons/Icons';
import { createModal } from '../../containers/Modal/ModalContext';
import { matchId } from '../../libraries/mongoose';
import Table, { TableBody, TableCell, TableFooter, TableHead, TableRow } from '../../mui/Table';
import i18n from '../../libraries/i18n';
import { getAlgorithmFinalCost } from '../Algorithm/algorithm.utils';
import DeleteIconEndpoint from '../../components/actions/icons/DeleteIconEndpoint';
import { ProjectContext } from '../Project/ProjectContext';
import { Category, UnitProduct } from '../UnitTemplate/unit-template.types';
import { ExtraOption } from './unit.types';
import { downloadDocument } from '../../libraries/files-saver';
import ConfirmModal from '../../containers/Modal/ConfirmModal';
import deleteResource from '../../containers/Notification/deleteResource';
import Typography from '../../mui/Typography';

export const unitProductState = (algorithms: AptlyAlgorithm[], product: ExtraOption | UnitProduct) => {
  const algorithmID =
    product.algorithm && typeof product.algorithm === 'object' ? product.algorithm._id : product.algorithm;
  const algorithm = algorithms?.find((x) => x._id === algorithmID);
  const cost = product.unitCost || 0;
  const finalCost = (algorithm ? getAlgorithmFinalCost(algorithm, cost) : cost) * product.quantity;
  return { cost, finalCost, algorithm };
};

interface Props {
  endpoint?: string;
  products: Array<UnitProduct | ExtraOption>;
  algorithms: AptlyAlgorithm[];
  addProduct?: () => void;
  createOrder?: () => void;
  onEdit?: (unitProduct: UnitProduct | ExtraOption, i: number) => void;
  onDelete?: (_id: string, i: number) => void;
  showPrerequisite?: boolean;
  categories?: Category[];
  disabled?: boolean;
}

export default function UnitProductTable({
  endpoint,
  products,
  algorithms,
  addProduct,
  createOrder,
  onEdit,
  onDelete,
  showPrerequisite,
  categories = [],
  disabled = false,
}: Props) {
  let baseCost = 0;
  let quantity = 0;
  let total = 0;

  const handleOnDelete = React.useCallback(
    (product: UnitProduct | ExtraOption, i: number) => {
      const label = product.addToNextOrder
        ? i18n.t('paragraphs.areYouSureToMoveFromQueue')
        : i18n.t('paragraphs.areYouSureToDelete');
      createModal(
        <ConfirmModal
          onConfirm={async () => {
            await deleteResource(
              `${endpoint}/${product._id}`,
              () => {
                product.addToNextOrder && onEdit
                  ? onEdit(
                      {
                        ...product,
                        addToNextOrder: false,
                      },
                      i
                    )
                  : onDelete!(product._id!, i);
              },
              true
            );
          }}
        >
          <Typography variant="body1">{label}</Typography>
        </ConfirmModal>
      );
    },
    [endpoint, onEdit, onDelete]
  );

  return (
    <Table>
      <UnitProductTableHead showPrerequisite={showPrerequisite} />
      <TableBody>
        {products.map((product, i) => {
          const { cost, finalCost, algorithm } = unitProductState(algorithms, product);
          baseCost += cost;
          quantity += product.quantity;
          total += finalCost;

          return (
            <UnitProductTableRow
              key={product._id}
              product={product}
              cost={cost}
              finalCost={finalCost}
              algorithm={algorithm}
              categories={categories}
              actions={
                <>
                  {onEdit && <EditIconButton onClick={() => onEdit(product, i)} />}
                  {onDelete && !product.projectItemRef && (
                    <DeleteIconEndpoint _id={product._id!} onClick={() => handleOnDelete(product, i)} />
                  )}
                </>
              }
              showPrerequisite={showPrerequisite}
            />
          );
        })}
      </TableBody>
      <UnitProductTableFooter
        baseCost={baseCost}
        quantity={quantity}
        total={total}
        addProduct={addProduct}
        createOrder={createOrder}
        showPrerequisite={showPrerequisite}
        disabled={disabled}
      />
    </Table>
  );
}

export function UnitProductTableHead({ showPrerequisite }: { showPrerequisite?: boolean }) {
  return (
    <TableHead>
      <TableRow>
        <TableCell>{i18n.t('singles.name')}</TableCell>
        <TableCell>{i18n.t('singles.description')}</TableCell>
        <TableCell>{i18n.t('singles.category')}</TableCell>
        <TableCell>{i18n.t('singles.deadline')}</TableCell>
        <TableCell>{i18n.t('singles.calculation')}</TableCell>
        <TableCell align="right">{i18n.t('singles.netCost')}</TableCell>
        <TableCell align="right">{i18n.t('singles.amount')}</TableCell>
        <TableCell align="right">{i18n.t('singles.totalPrice')}</TableCell>
        {showPrerequisite && <TableCell align="right">{i18n.t('paragraphs.triggerAtCost')}</TableCell>}
        <TableCell align="right">{i18n.t('singles.actions')}</TableCell>
      </TableRow>
    </TableHead>
  );
}

interface UnitProductTableRowProps {
  product: ExtraOption | UnitProduct;
  cost: number;
  finalCost: number;
  algorithm?: AptlyAlgorithm;
  actions?: JSX.Element;
  showPrerequisite?: boolean;
  categories: Category[];
}

export function UnitProductTableRow({
  product,
  cost,
  finalCost,
  algorithm,
  actions,
  showPrerequisite,
  categories,
}: UnitProductTableRowProps) {
  const project = useContext(ProjectContext);
  const productDoc = product.product as AptlyProduct | undefined;
  const prod =
    product.variant && productDoc?.variants
      ? productDoc.variants.find((x) => x._id === product.variant) || productDoc
      : productDoc;
  const name = prod?.name || product.text;
  const document = product.params?.find((x) => x.key === 'document');
  const category =
    'unitTemplateCategory' in product && product.unitTemplateCategory
      ? categories.find((x) => x._id === product.unitTemplateCategory)
      : null;
  const period = product.period ? project.data.periods.find((x) => matchId(x, product.period!)) : null;

  return (
    <TableRow key={product._id}>
      <TableCell>{name}</TableCell>
      <TableCell>{product.customDescription}</TableCell>
      <TableCell>{category ? category.name : '-'}</TableCell>
      <TableCell>{period ? period.name : '-'}</TableCell>
      <TableCell>{algorithm ? algorithm.name : '-'}</TableCell>
      <TableCell align="right">{cost}</TableCell>
      <TableCell align="right">{product.quantity}</TableCell>
      <TableCell align="right">{finalCost}</TableCell>
      {showPrerequisite && (
        <TableCell align="right">
          {('prerequisites' in product && product.prerequisites?.totalCost) ?? i18n.t('singles.NA')}
        </TableCell>
      )}

      <TableCell align="right">
        {document && document.downloadToken && (
          <DownloadIconButton
            onClick={() => {
              downloadDocument(document);
            }}
          />
        )}
        {actions}
      </TableCell>
    </TableRow>
  );
}

interface UnitProductTableFooterProps {
  baseCost: number;
  quantity: number;
  total: number;
  addProduct?: () => void;
  createOrder?: () => void;
  showPrerequisite?: boolean;
  disabled?: boolean;
}

export function UnitProductTableFooter({
  baseCost,
  quantity,
  total,
  addProduct,
  createOrder,
  showPrerequisite,
  disabled,
}: UnitProductTableFooterProps) {
  return (
    <TableFooter>
      <TableRow>
        <TableCell>{i18n.t('singles.total')}</TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell align="right">{baseCost}</TableCell>
        <TableCell align="right">{quantity}</TableCell>
        <TableCell align="right">{total}</TableCell>
        {showPrerequisite && <TableCell align="right" />}
        <TableCell align="right">
          {addProduct && (
            <AddIconButton title={i18n.t('actions.add')} onClick={addProduct} disabled={disabled} />
          )}
          {createOrder && (
            <SendIconButton title={i18n.t('actions.sendOrder')} onClick={createOrder} disabled={disabled} />
          )}
        </TableCell>
      </TableRow>
    </TableFooter>
  );
}
