import { useCallback, useContext, useState } from 'react';
import { AptlyProduct } from '@aptly-as/types';
import { EditIconButton, OpenInNewIconButton } from '../../components/actions/icons/Icons';
import { IGridActionProps } from '../../components/Search/search-data-grid/data-grid.actions';
import { createModal } from '../../containers/Modal/ModalContext.js';
import { SearchCrudContext } from '../../components/Search/search.utils';
import ProductEdit from './ProductEdit';

interface State {
  product: null | AptlyProduct;
  index: number;
}

interface Props extends IGridActionProps {
  update?: boolean;
}

export function DataGridEditProduct({ params, update }: Props) {
  const {
    items,
    actions: { splice },
  } = useContext(SearchCrudContext);
  const { id } = params;
  const [, setState] = useState<State>({
    product: null,
    index: -1,
  });

  const handleOnClosed = useCallback(
    (product: AptlyProduct, index: number) => {
      splice(index, 1, product);
    },
    [splice]
  );

  const handleOnDelete = useCallback(() => {
    setState((s) => {
      if (s.index > -1) {
        splice(s.index, 1);
      }
      return { product: null, index: -1 };
    });
  }, [splice]);

  const handleOnShow = useCallback(() => {
    setState((s) => {
      if (s.product) {
        handleOnClosed(s.product, s.index);
        return { product: null, index: -1 };
      }
      const index = items.findIndex((x) => x._id === id);
      return { product: items[index], index };
    });
  }, [handleOnClosed, items, id]);

  const handleOnChange = useCallback((product: AptlyProduct) => {
    setState((s) => ({ ...s, product }));
  }, []);

  const handleOnEdit = useCallback(() => {
    createModal(
      <ProductEdit
        product={params.row}
        onChange={handleOnChange}
        onDelete={handleOnDelete}
        onClose={handleOnShow}
        update={update}
      />,
      { width: 'md' }
    );
  }, [params.row, handleOnChange, handleOnDelete, handleOnShow, update]);

  return (
    <>{update ? <EditIconButton onClick={handleOnEdit} /> : <OpenInNewIconButton onClick={handleOnEdit} />}</>
  );
}
