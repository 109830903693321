import { useCallback, useRef, useState } from 'react';

export type IThrottleFn<T> = (fn: (state: T) => T, overrideWait?: number) => void;
type Return<T extends ValidTypes> = [state: T, setState: IThrottleFn<T>];
type ValidTypes = object | string | number;

export function useStateThrottle<T extends ValidTypes>(
  state: T,
  wait = 500,
  fn?: (state: T) => void
): Return<T> {
  const waitTimer = useRef<number | null>(null);
  const ref = useRef<T>(state);
  const [_state, _setState] = useState<T>(state);

  const setState: IThrottleFn<T> = useCallback(
    (state: (state: T) => T, overrideWait = wait) => {
      ref.current = typeof state === 'function' ? state(ref.current) : state;
      if (overrideWait > 0) {
        if (waitTimer.current) {
          clearTimeout(waitTimer.current);
        }

        waitTimer.current = setTimeout(() => {
          _setState(ref.current);
          if (typeof fn === 'function') {
            fn(ref.current);
          }
        }, overrideWait);
      } else {
        _setState(ref.current);
        if (typeof fn === 'function') {
          fn(ref.current);
        }
      }
    },
    [wait, fn]
  );

  return [_state, setState];
}
