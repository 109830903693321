export const IS_DEV = import.meta.env.DEV;

export const API_URL = import.meta.env.VITE_API_URL || '';
export const CLIENT_URL = import.meta.env.VITE_CLIENT_URL || '';
export const CLOUDINARY_KEY = import.meta.env.VITE_CLOUDINARY_KEY || '';

export const FORMAT_DATE = 'DD.MM.YY';
export const FORMAT_DATE_TIME = 'DD.MM.YY HH:mm';
export const FORMAT_TIME = 'HH:mm';

export const LOGO = import.meta.env.VITE_APP_LOGO || 'logo.png';

export const APTLY_PRIMARY_HEX_COLOR = import.meta.env.VITE_PRIMARY_COLOR || '#2AD1AC';
export const APTLY_SECONDARY_HEX_COLOR = '#2A313F';

export const LOG_ID = import.meta.env.VITE_LOG_ID;
export const GOOGLE_API_KEY = import.meta.env.VITE_GOOGLE_API_KEY;

export const RECRUIT_CAMPAIGN_URL = import.meta.env.VITE_RECRUIT_CAMPAIGN_URL;
export const RECRUIT_CAMPAIGN_REF = import.meta.env.VITE_RECRUIT_CAMPAIGN_REF;
