import { IUseFieldRet } from '@ewb/reach-react';
import { IApiError } from '../../ApiError.js';
import { AutocompleteSearch } from '../../AutocompleteSearch.js';
import { ICrudFieldData } from '../../crud/utils/crud.utils.js';
import { ISimpleComponentProps } from './simple-components.utils';

export interface ICrudSimpleAutocompleteSearchProps<T extends object> extends ISimpleComponentProps<T, any> {
  crud: IUseFieldRet<T, IApiError, ICrudFieldData<T>>;
}

export default function SimpleAutocompleteSearch<T extends object>({
  crud,
  field,
  onChange,
}: ICrudSimpleAutocompleteSearchProps<T>) {
  const { value, id, disabled, label, defaultValue, useAutocompleteSearch } = field;
  if (!useAutocompleteSearch) {
    throw new Error(`Field "${field}" is missing useAutocomplete object`);
  }
  const autocompleteProps = useAutocompleteSearch();

  if (
    (!crud.state.edited && crud.state.busy && typeof value === 'string') ||
    (Array.isArray(value) && typeof value[0] === 'string')
  ) {
    return null;
  }

  return (
    <AutocompleteSearch
      id={id}
      key={id}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      disabled={disabled}
      textFieldProps={{ label, disabled }}
      {...autocompleteProps}
    />
  );
}
