import Autocomplete, { AutocompleteChangeReason, AutocompleteValue } from '@mui/material/Autocomplete';
import { AutocompleteProps, AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete.js';
import TextField from '@mui/material/TextField';
import { TextFieldProps } from '@mui/material/TextField/TextField.js';
import { SyntheticEvent, useCallback } from 'react';
import i18n from '../libraries/i18n.js';
import { SelectOption } from '../mui/Select.js';

const getACLabel = <Value extends SelectOption>(v: Value): string => v?.label || v;
const getACKey = <Value extends SelectOption>(v: Value): string => v?.value || v;
export type ACValue<Multiple = false, DisableClearable extends boolean = false> = AutocompleteValue<
  SelectOption,
  Multiple,
  DisableClearable,
  false
>;

export interface AutocompleteSelectProps<
  Multiple extends boolean = false,
  DisableClearable extends boolean = false,
> extends Omit<
    AutocompleteProps<SelectOption, Multiple, DisableClearable, false>,
    'renderInput' | 'autoComplete' | 'onChange'
  > {
  onChange: (value: ACValue<Multiple, DisableClearable>) => void;
  textFieldProps?: TextFieldProps;
}

export function AutocompleteSelect<
  Multiple extends boolean = false,
  DisableClearable extends boolean = false,
>({ onChange, textFieldProps, ...autoCompleteProps }: AutocompleteSelectProps<Multiple, DisableClearable>) {
  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField {...params} label={i18n.t('actions.search')} fullWidth {...textFieldProps} />
    ),
    [textFieldProps]
  );

  const handleOnChange = useCallback(
    (_: SyntheticEvent, value: ACValue<Multiple, DisableClearable>, reason: AutocompleteChangeReason) => {
      if (reason === 'selectOption' || reason === 'clear') {
        onChange(value);
      }
    },
    [onChange]
  );

  return (
    <Autocomplete<SelectOption, Multiple, DisableClearable, false>
      renderInput={renderInput}
      autoComplete
      getOptionKey={getACKey}
      getOptionLabel={getACLabel}
      noOptionsText={i18n.t('paragraphs.noChoices')}
      onChange={handleOnChange}
      disableCloseOnSelect={autoCompleteProps.multiple}
      {...autoCompleteProps}
    />
  );
}
