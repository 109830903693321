import { AptlyScopes } from '@aptly-as/types';
import Grid2 from '@mui/material/Grid2';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteResourceButton from '../../components/actions/buttons/DeleteResourceButton';
import { ListItem } from '../../components/List/List';
import Section from '../../components/Section';
import CrudSettingsListField, {
  CrudSettingsAutocompleteSearch,
  CrudSettingsListReference,
  CrudSettingsSelect,
  SingleSettingsListField,
  SingleSettingsListMedia,
} from '../../components/Settings/CrudSettingsListField';
import SettingsSection from '../../containers/Settings/SettingsSection';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { FragmentScope, LayoutScope } from '../../libraries/scope/ScopeComponets';
import Grid from '../../mui/Grid';
import Typography from '../../mui/Typography';
import { rawSpacing } from '../../utils/spacing';
import { OrganizationContext } from './OrganizationContext';
import OrganizationIntegrations from './organizationSettings/OrganizationIntegrations';
import OrganizationModules from './organizationSettings/OrganizationModules';

function OrganizationSettings() {
  const navigate = useNavigate();
  const org = useContext(OrganizationContext);
  const { data } = org;
  const endpoint = useApiUrl(SlugLevel.Base, 'organizations');

  if (!data) {
    return null;
  }

  return (
    <>
      <Section>
        <Typography variant="h1">{i18n.t('singles.settings')}</Typography>
      </Section>
      <Section>
        <Grid2 container gap={2}>
          <Grid2 size={{ xs: 12, lg: 5 }}>
            <Typography variant="h2" gutterBottom>
              {i18n.t('singles.general')}
            </Typography>
            <Grid container spacing={rawSpacing} direction="column" wrap="nowrap">
              <Grid item>
                <SettingsSection>
                  <CrudSettingsListField crud={org} field="email" divider />
                  <CrudSettingsListField crud={org} field="number" divider />
                  <CrudSettingsListField crud={org} field="complaintEmail" divider />
                </SettingsSection>
              </Grid>
              <Grid item>
                <SettingsSection title={i18n.t('singles.signing')}>
                  <CrudSettingsListReference crud={org} field="signApp" divider />
                  <CrudSettingsListReference crud={org} field="signMembers" divider />
                </SettingsSection>
              </Grid>
              <Grid item>
                <SettingsSection title={i18n.t('singles.payment')}>
                  <CrudSettingsListReference crud={org} field="paymentApp" divider />
                  <CrudSettingsListField crud={org} field="invoiceEmail" />
                </SettingsSection>
              </Grid>
              <Grid item>
                <SettingsSection title={i18n.t('address.billing')}>
                  <SingleSettingsListField crud={org} field="address.billing.line1" divider />
                  <SingleSettingsListField crud={org} field="address.billing.line2" divider />
                  <SingleSettingsListField crud={org} field="address.billing.zip" divider />
                  <SingleSettingsListField crud={org} field="address.billing.city" divider />
                  <SingleSettingsListField crud={org} field="address.billing.country" />
                </SettingsSection>
              </Grid>
              <Grid item>
                <SettingsSection title={i18n.t('singles.look')}>
                  <SingleSettingsListMedia crud={org} field="logo" divider />
                  <SingleSettingsListMedia crud={org} field="negativeLogoMedia" divider />
                  <SingleSettingsListMedia crud={org} field="projectLogoMedia" divider />
                </SettingsSection>
              </Grid>
            </Grid>
          </Grid2>
          <FragmentScope scope={AptlyScopes.Admin} crud="U">
            <Grid2 size={{ xs: 12, lg: 5 }}>
              <Typography variant="h2" gutterBottom>
                {i18n.t('singles.admin')}
              </Typography>
              <Grid container spacing={rawSpacing} direction="column" wrap="nowrap">
                <Grid item>
                  <SettingsSection>
                    <CrudSettingsListField crud={org} field="name" divider />
                    <CrudSettingsListField crud={org} field="vat" divider />
                    <CrudSettingsSelect crud={org} field="status" divider />
                    <CrudSettingsSelect crud={org} field="type" />
                    <CrudSettingsListField crud={org} field="slug" divider />
                    <CrudSettingsAutocompleteSearch crud={org} field="tripletexCustomer" />
                  </SettingsSection>
                </Grid>
                <Grid item>
                  <SettingsSection title={i18n.t('singles.producer')}>
                    <CrudSettingsListReference crud={org} field="producer" />
                  </SettingsSection>
                </Grid>
                <LayoutScope item scope={AptlyScopes.Organization} crud="C">
                  <OrganizationIntegrations organization={data} />
                </LayoutScope>
                <LayoutScope item scope={AptlyScopes.Organization} crud="C">
                  <OrganizationModules
                    organization={data}
                    onSave={(data) => {
                      if (data) {
                        org.setData({ modules: data.modules });
                      }
                    }}
                  />
                </LayoutScope>
                <LayoutScope item scope={AptlyScopes.Organization} crud="D">
                  <Grid item>
                    <SettingsSection title={i18n.t('singles.other')}>
                      <ListItem
                        secondaryAction={
                          <DeleteResourceButton
                            endpoint={`${endpoint}/${data.slug}`}
                            onClick={() => navigate('/')}
                          >
                            {i18n.t('actions.archive')}
                          </DeleteResourceButton>
                        }
                      >
                        {i18n.t('actions.archiveOrganization')}
                      </ListItem>
                    </SettingsSection>
                  </Grid>
                </LayoutScope>
              </Grid>
            </Grid2>
          </FragmentScope>
        </Grid2>
      </Section>
    </>
  );
}

export default OrganizationSettings;
